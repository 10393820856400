import { SERVER_ERROR, SET_ALERT, LOADING, STORE_NAME, CLEAR_ALERT, CLEAR_STATE, ERR_TOKEN, SET_CONFIRMATION } from "./actionTypes";
import { put } from 'redux-saga/effects';
import { ReducerRegistry } from "../ReducerRegistry"

function* onSagaErrorCatch(error, type = SERVER_ERROR) {
  error = typeof error == "string" ? JSON.parse(error) : error;
  const alert = { success: false, message: (error.data && error.data.message) || 'Request Failed' }
  yield setAlertAndLoading(false, alert)
}
export function* setAlertAndLoading(isLoading = null, alert = null) {
  if (alert !== null) {
    alert.severity = alert.success === false ? 'error' : 'success';
    yield put({ type: SET_ALERT, payload: alert });
  }
  if (isLoading !== null) {
    yield put({ type: LOADING, payload: isLoading });
  }
}
export function* setConfimationYield(confimation) {
  yield put({ type: SET_CONFIRMATION, payload: confimation });
}
export function* requestHandler(callback) {
  try {
    yield callback()
  } catch (error) {
    console.log("error", error);
    yield onSagaErrorCatch(error, SET_ALERT);
  }
}
const DEFAULT_STATE = { loading: false }
// export * from "./reducer";
export * from "./actionTypes";

export function getLoading(state) {
  return state[STORE_NAME].loading
}
export function getAlert(state) {
  return state[STORE_NAME].alert
}
export function getConfirmation(state) {
  return state[STORE_NAME].confirmation
}
export function clearAlert() {
  return { type: CLEAR_ALERT }
}
export function setAlert(error) {
  return { type: SET_ALERT, payload: error }
}
export function setConfirmation(data) {
  return { type: SET_CONFIRMATION, payload: data }
}
export function clearState() {
  return { type: CLEAR_STATE }
}
export function isTokenInvalid(state) {
  return state[STORE_NAME].InvalidToken
}
ReducerRegistry.register(STORE_NAME, function (state, { type, payload }) {
  if(!state){
    state = DEFAULT_STATE
  }
  switch (type) {
    case LOADING: return { ...state, loading: payload };
    case CLEAR_ALERT: return { ...state, alert: false };
    case SET_ALERT: return { ...state, alert: payload, InvalidToken: payload.type === ERR_TOKEN }
    case SET_CONFIRMATION: return { ...state, confirmation: payload }
    case CLEAR_STATE: return { ...DEFAULT_STATE }
    default: return state;
  }
})
export function getMultipartHeader() {
  return { 'content-type': 'multipart/form-data' }
}