import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { AppErrorBoundry, AutoCompleteSelect, Button } from "../../components";
import { fetchNotifFreqList, getNotificationFreqs, updateNotifFreq, getUser } from "../../store";
import "./settings.scss";

const SettingsScreen = (props) => {
  const { user } = props
  const [notify, setNotify] = useState(user.ent_org ? (user.ent_org.notification_frequency || null) : null)
  useEffect(() => {
    document.title = "Arc | Settings";
    props.fetchNotifFreqList()
  }, []);
  const onChange = (e) => {
    const { value } = e.target;
    setNotify(value)
  }
  const onUpdate = () => {
    props.updateNotifFreq(notify)
  }
  return (
    <main className='col flex-full main'>
      <AppErrorBoundry>
        <div className='settings-view'>
          <div className='h21 label wt-med exo2'>Notifications</div>
          <form className='settings-form'>
            <div className='h21 label wt-med exo2'>Comments Notification Frequency</div>
            <div className='row form-input'>
              <span className='h21 label wt-med'>Notify</span>
              <AutoCompleteSelect
                value={notify}
                options={props.frequencies}
                name='notify'
                containerClass='select-group h41'
                onChange={onChange}
              />
            </div>
            <Button
              text='Update'
              color='#1177CC'
              className='h4 update'
              onClick={onUpdate}
              endIcon={(
                <i className={`h4 icon-arrow-round-forward`} />
              )}
            />
          </form>

        </div>
      </AppErrorBoundry>
    </main>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifFreqList: () => dispatch(fetchNotifFreqList()),
    updateNotifFreq: (freq) => dispatch(updateNotifFreq(freq))
  }
}
const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    frequencies: getNotificationFreqs(state)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
