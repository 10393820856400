import React from 'react';
import { useNavigate } from "react-router-dom";
import { AddButton, } from "../../components";
import { Grid, Button, Divider } from "@mui/material";
import NotFoundOval from "../../assets/images/Eval.png";
import "./notfound.scss";

export const PageNotFound = (props) => {
  const navigate = useNavigate();
  return (
    <div className='full col page-not-found'>
      <Grid container classes={{root: 'h-100'}}>
        <Grid item xs={12} sm={12} md={6} lg={6} >
          <div className='col oval-container text-container'>
            <div className='exo2 txt clang italic'>CLANG!</div>
            <div className='bold txt txt-404'>Page Not Found</div>
            <p className='txt-info'>What you're looking for might be renamed, <br />removed, or might not exist!</p>
            <AddButton disableElevation className='h5 back-home' text='Back to Home Page' showIcon={false} onClick={() => navigate("/")} />
          </div>
        </Grid>
        <img alt='Oval-Not-Found' className='oval-404' src={NotFoundOval} />
      </Grid>
      <div className='flex-ctr row term-container'>
        <Button disableElevation disableRipple className='h3 exo2 btn-back' classes={{ text: 'text' }}>Terms & Conditions</Button>
        <Divider orientation="vertical" classes={{ root: 'divider' }} />
        <Button disableElevation disableRipple className='h3 exo2 btn-back' classes={{ text: 'text' }}>Privacy Policy</Button>
      </div>
    </div>
  )
}