import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Grid, Button, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import { Button as AppButton } from "../../components";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FileImg from "../../assets/images/file.png";
import BulkUploadTemplate from "../../assets/bulk-upload-template.xlsx";
import { axios } from "../../utils/axios";
import { saveAs } from 'file-saver';
import { uploadBulkTask, isBulkUploadCompleted, getBulkUploads, fetchBulkUploads, setAlert } from "../../store"

const Message = (props) => {
  const { type, title, messages, className } = props
  return (
    <div className={'row h6 msg-cont ' + className}>
      <span className={'type ' + type}>{title}</span>
      <div className='col'>
        {
          messages.map((msg, i) => {
            return <span key={i} className='msg'>{msg}</span>
          })
        }
      </div>
    </div>
  )
}
const TableColumns = [
  { header: 'File Name', width: '75%', attribute: 'document_file_name' },
  { header: 'Uploaded', width: '15%', attribute: 'created_at', date: true },
  { header: 'Results', width: '10%', attribute: 'error_file_path', link: true },
]
const StatusTable = (props) => {
  const { uploads, maxHeight } = props
  return (
    <div className='col prev-table-cont flex-full' style={{ maxHeight, overflow: 'auto' }}>
      <Table aria-label="simple table" classes={{ root: 'h5' }} stickyHeader>
        <TableHead className='header-row'>
          <TableRow>
            {
              TableColumns.map((column) => {
                return (
                  <TableCell
                    width={column.width}
                    key={column.attribute}
                    classes={{ root: '' }}>
                    {column.header}
                  </TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
        {
          Boolean(uploads) &&
          <TableBody>
            {
              uploads.map((_upload, i) => {
                return (
                  <TableRow key={i}>
                    {
                      TableColumns.map((column) => {
                        return (
                          <TableCell
                            width={column.width}
                            key={column.attribute}
                            classes={{ root: '' }}>
                            {
                              column.date ?
                                moment(_upload[column.attribute]).format('ddd, DD-MMM-YY')
                                : column.link ?
                                  <a href='#' onClick={(e) => {
                                    e.preventDefault();
                                    props.onDownload(_upload)
                                  }}>Download</a>
                                  :
                                  _upload[column.attribute]
                            }
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        }
      </Table>
    </div>
  )
}
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const BulkUploadScreen = (props) => {
  const navigate = useNavigate();
  const { isBulkUploadCompleted } = props
  const [fileName, setFileName] = useState('Select File');
  const [blink, setBlink] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const upload = usePrevious(props);
  const fileRef = useRef(null)
  const tableRef = useRef(null)
  useEffect(() => {

    if (upload && upload.isBulkUploadCompleted && !isBulkUploadCompleted) {
      const form = document.getElementById('bulk-upload-form');
      form.reset();
      setBlink(true)
      setTimeout(() => setBlink(false), 30000);
    }
  }, [isBulkUploadCompleted])
  useEffect(() => {
    document.title = "Arc | Task Bulk Upload";
    props.fetchBulkUploads();
  }, [])
  useEffect(() => {
    if (Array.isArray(props.prevUploads) && tableRef && tableRef.current) {
      setTableHeight(tableRef.current.clientHeight - 8)
    }
  }, [props.prevUploads])
  const onFormSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('bulk-upload-form');
    const data = new FormData(form);
    props.uploadBulkTask(data)
  }
  const onDownload = (upload) => {
    if (upload && upload.error_file_path) {
      axios.post("/tasks/task_upload_status_file", { file_location: upload.error_file_path })
        .then(cont => {
          cont = cont.replaceAll("|", ',');
          var blob = new Blob([cont], { type: 'text/csv;charset=utf-8;' });
          let fileName = String(upload.document_file_name).replace('.', '_Status.')
          saveAs(blob, fileName)
        })
        .catch((err) => {
          props.setAlert({ severity: 'error', message: "Error While Downloading File" })
        })
    }
  }
  return (
    <div className='col full main bulk-upload'>
      <div className='row back'>
        <Button disableElevation disableRipple startIcon={<ArrowBack htmlColor='#073C3C' />} className='h4 exo2 btn-back' onClick={() => {
          navigate("/T" + window.location.search)
        }}>
          Bulk Upload Tasks
        </Button>
      </div>
      <Grid container >
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className='form-container'>
          <form id='bulk-upload-form' onSubmit={onFormSubmit}>
            <div className='row form-row'>
              <div className='col form-control'>
                <a href={BulkUploadTemplate} download="Bulk Upload Template.xlsx" target="_blank" className='h7 exo2 template'>Download Bulk Upload Template</a>
                <div className='row file-control space-btn'>
                  <span className='h5 file-name flex-full'>{fileName}</span>
                  <input hidden name='file' ref={fileRef} type='file' onChange={(e) => {
                    if (e && e.target.files.length > 0) {
                      setFileName(e.target.files[0].name)
                    }
                  }} />
                  <AppButton
                    text='Choose File'
                    color='#1177CC'
                    className='h6 btn-choose'
                    disabled={props.isBulkUploadCompleted}
                    onClick={(e) => {
                      if (fileRef && fileRef.current) {
                        fileRef.current.click()
                      }
                    }}
                    endIcon={<img src={FileImg} className='icon-file' alt='file icon' />} />
                </div>
              </div>
              <AppButton className='h4 btn-upload' loading={props.isBulkUploadCompleted} disabled={props.isBulkUploadCompleted} type="submit" text='Upload' endIcon={<ArrowForward />} />
            </div>
          </form>
          <Message
            type='important'
            title='Important!'
            messages={["Tasks with missing Users and Department will be ignored", 'Duplicate Tasks will be ignored']} />
          <Message
            type='success'
            title='Success!'
            className={blink ? 'blink_me' : 's-hide'}
            messages={['Tasks with no errors are successfully created!', 'Please check the Results file for Tasks that were not created.']} />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <div className='help-section'>
            <div className='col'>
              <div className='title'>Help Section</div>
              <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
          </div>
        </Grid> */}
      </Grid>
      {
        (Array.isArray(props.prevUploads) && props.prevUploads.length > 0) &&
        <div className='col prev-cont  flex-full' ref={tableRef}>
          <h6 className='h4 exo2'>Previous Upload Status</h6>
          <StatusTable uploads={props.prevUploads} maxHeight={tableHeight} onDownload={onDownload} />
        </div>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadBulkTask: (file) => dispatch(uploadBulkTask(file)),
    fetchBulkUploads: () => dispatch(fetchBulkUploads()),
    setAlert: (er) => dispatch(setAlert(er))
  }
}
const mapStateToProps = (state) => {
  return {
    isBulkUploadCompleted: isBulkUploadCompleted(state),
    prevUploads: getBulkUploads(state)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadScreen);
