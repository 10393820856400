import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn, login, validateToken, decryptDataObject } from "../../store";

const LogInScreen = (props) => {
  const navigate = useNavigate();
  const mounted = useRef();

  useEffect(() => {
    let urlObj = new URLSearchParams(window.location.search);
    document.title = "Arc | Login";
    const sessionToken = urlObj.get('sessionToken');
    if (sessionToken) {
      props.validateToken(sessionToken);
    } else {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (props.isLoggedIn) {
        let redirect, path = '', urlObj;
        urlObj = new URLSearchParams(window.location.search);
        redirect = urlObj.get('redirect');
        urlObj.delete('sessionToken');
        if (redirect) {
          let dataObj = decryptDataObject(redirect);
          if (Object.values(dataObj).length > 0) {
            if (dataObj.path) {
              path += dataObj.path;
            }
            if (dataObj.search) {
              path += dataObj.search;
            }
          }
        } else {
          path = '/'
        }
        navigate(path)
      }
    }
  });
  return (
    <div className='col flex-ctr full jg-login'>
      <div className='h5'>Please wait...</div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    validateToken: (token) => dispatch(validateToken(token)),
  }
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LogInScreen);
