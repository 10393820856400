import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainRouter } from "./main.router";
import { LogInScreen, PageNotFoundScreen } from "../screens";

function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/*" element={<MainRouter />} />
        <Route path="/login" element={<LogInScreen />} />
        <Route path="*" element={<PageNotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
