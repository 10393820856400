import { ReducerRegistry } from "../ReducerRegistry";
import {
  STORE_NAME, LOGOUT_SUCCESS, UPDATE_NOTIF_FREQ_SUCCESS,
  LOGIN_SUCCESS, VALIDATE_TOKEN_SUCCESS
} from "./actionsType";
import { CLEAR_STATE } from "../common";

const DEFAULT_STATE = {};

ReducerRegistry.register(STORE_NAME, function (state, { type, payload }) {
  if(!state){
    state = DEFAULT_STATE
  }
  switch (type) {
    case LOGIN_SUCCESS: return { ...state, loggedOut: false, loginState: true, user: payload.current_ent_usr }
    case VALIDATE_TOKEN_SUCCESS: return { ...state, loggedOut: false, loginState: true, user: payload }
    case LOGOUT_SUCCESS: return { ...state, loggedOut: true }
    case CLEAR_STATE: return { ...DEFAULT_STATE }
    case UPDATE_NOTIF_FREQ_SUCCESS: {
      let user = { ...state.user, ent_org: payload };
      return { ...state, user: user }
    }
    default:
      return state
  }
})