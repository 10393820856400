export const STATUS_COLOR_CODE = {
  Total: '#67b7dc',
  Pending: "#FC4343",
  Open: "#FC4343",
  Responded: "#FCBC03",
  Revision: "#FCBC03",
  InProgress: "#FCFC43",
  Complete: "#87FC87",
  Compliant: "#87FC87",
  Hold: "#CC0303",
  Skipped: "#787878",
  Abandoned: "#787878",
  Duplicate: "#787878",
  Redundant: "#787878",
  Review: "#FCFC87",
  Reviewed: "#43DD43",
  Closed: "#238723",
}
const PIEColors = [
  '#9172d5',
  '#b05bfe',
  '#a68cd5',
  '#ac6cd5',
  '#b383d3',
  '#c577d1',
  '#dc67ce',
  '#5c38b0',
  '#6771DC',
  '#7b70dc',
  '#8599ff',
  '#a899ff',
  '#abb5ff',
  '#5f7bb1',
  '#80a6d9',
  '#839dd9',
  '#84a3d8',
  '#9b93dc',
  '#67b7dc',
  '#8d93e3',
  '#bf7bd1',
  '#cf6dd0',
  '#997a7a',
  '#a78bd4',
  '#6794dc',
];
const BAROptions = {
  plugins: {
    legend: {
      display: true,
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      min: 0,
      max: 10,
      stacked: false,
    }
  },
}
const PIEOptions = {
  zoomOutPercentage: 40,
  legend: {
    display: true,
    labels: {
      fontSize: 14,
      fontFamily: 'Roboto'
    },
    position: 'bottom'
  },
  plugins: {
    outlabels: {
      // text: `%v`,
      text: (label, percentage, value) => `$${value}`,
      color: "white",
      stretch: 10,
      font: {
        resizable: true,
        minSize: 12,
        maxSize: 18
      }
    }
  }
}

export const getPieOptions = () => {
  let options = JSON.parse(JSON.stringify(PIEOptions));
  return options;
}
export const getPIEColors = (len) => {
  return PIEColors.slice(0, len)
}
export const getBarOptions = (yMax, stacked = false) => {
  let options = JSON.parse(JSON.stringify(BAROptions));
  options.scales.y.max = yMax
  if (stacked) {
    options.scales.x.stacked = true
    options.scales.y.stacked = true
  }
  return options;
}