export const STORE_NAME = "USER";
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_NOTIF_FREQ_SUCCESS = 'UPDATE_NOTIF_FREQ_SUCCESS';

export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';

export const ROLE_RESPONSIBLE = "Responsible";
export const ROLE_REVIEWER = "Reviewer";


