import React, { useEffect, useRef, useState } from 'react';
import { Textarea, ConfirmationBox, InputContainer, AppErrorBoundry, AutoCompleteSelect, UserAvatar, AvatarCard, DatePicker, UserSelect, Button, IconButton } from "../../components";
import { Dialog, Accordion, Grid, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import { ExpandMore, Send, Save, Close, TaskAlt, BookOnline } from '@mui/icons-material';
import Attachfile from "../../assets/images/attach-file.svg";
import { connect } from 'react-redux';
import {
  updateTask, isTaskFetched, getStatusList, getTask, addTaskComment, isCommentAdded, attachTaskEvidence, isEvidenceAttached,
  getAccountableById, getUser, getDepartmentAuditee, getSupervisorById, isResponsible, isReviewer, isObserveAdded, updateIsObserveAdded,
  getTaskResultList, isSupervisor
} from "../../store";
import moment from 'moment';
import { saveAs } from 'file-saver';
// import Close from "../../assets/images/close.png";


const TaskInfo = (props) => {
  const { label, description, ...rest } = props
  return (
    <Grid item className='info' {...rest}>
      <div className='h7 exo2 label'>{label}:</div>
      <p className='h5 roboto desc'>
        {description}
      </p>
    </Grid>
  )
}
const getTaskResponsibles = (resp, user, task) => {
  let result = resp.map((_) => {
    return { ..._ }
  });
  let isExist = result.find((_) => _.id === user.id)
  if (!isExist && user.id === task.assignee_id) {
    result.push({ ...user });
  } else {
    isExist = result.find((_) => task.assignee && _.id === task.assignee.id);
    if (!isExist) {
      result.push({ ...task.assignee })
    }
  }
  return result
}
const mapStateToProps = (state, ownProps) => {
  const { taskId } = ownProps;
  const task = getTask(state, taskId);
  const responsible = getDepartmentAuditee(state, task.department)
  const user = getUser(state);
  let supervisor = getSupervisorById(state, task.supervisor_id);
  if (!supervisor && task.supervisor) {
    supervisor = task.supervisor
  }
  return {
    task: task,
    user: user,
    isResponsible: isResponsible(state),
    isReviewer: isReviewer(state),
    isObserveAdded: isObserveAdded(state),
    statusList: getStatusList(state),
    isCommentAdded: isCommentAdded(state),
    isEvidence: isEvidenceAttached(state),
    isTaskFetched: isTaskFetched(state),
    accountable: getAccountableById(state, task.accountable_id),
    responsible: getTaskResponsibles(responsible, user, task),
    supervisor: supervisor,
    isSupervisor: isSupervisor(task.supervisor_id, user)
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { taskId } = ownProps;
  return {
    addTaskComment: (comment) => dispatch(addTaskComment(taskId, comment)),
    attachTaskEvidence: (document) => dispatch(attachTaskEvidence(taskId, document)),
    updateTask: (update, popup) => dispatch(updateTask(update, taskId, popup)),
    updateIsObserveAdded: (value) => dispatch(updateIsObserveAdded(value))
  }
}
const Comment = (props) => {
  const { comment, task } = props;
  return (
    <div className='row start'>
      <UserAvatar user={comment.creator || task.creator} />
      <div className='col flex-full'>
        <div>
          {
            Boolean(comment.creator || task.creator) &&
            <span className='h7 creator rmedimum'>{(comment.creator || task.creator).firstname} {(comment.creator || task.creator).lastname}</span>
          }
          <span className='h8 date'>{moment(comment.updated_at).format('ddd, DD-MMM-YY HH:mm')}</span>
        </div>
        <p className='h5 msg'>
          {comment.comment || comment.auditee_resp_comment}
        </p>
      </div>
    </div>
  )
}
const Evidence = (props) => {
  const { attachment, task } = props;
  return (
    <div className='row start evidence'>
      {
        Boolean(attachment.creator || task.creator) &&
        <UserAvatar user={attachment.creator || task.creator} />
      }
      <div className='col flex-full'>
        <a className='h5 file' onClick={props.onDownload}>
          {attachment.document_file_name || attachment.evidence_file_name}
        </a>
        <div className='name'>
          {
            Boolean(attachment.creator || task.creator) &&
            <span className='h7 rmedimum'>{(attachment.creator || task.creator).firstname} {(attachment.creator || task.creator).lastname}</span>
          }
          <span className='h8 date'>{moment(attachment.document_updated_at || attachment.updated_at).format('ddd, DD-MMM-YY HH:mm')}</span>
        </div>
      </div>
    </div>
  )
}
export const ViewTask = connect(mapStateToProps, mapDispatchToProps)((props) => {
  const { task, isCommentAdded, isEvidence, isObserveAdded } = props;
  const evidenceRef = useRef(null)
  const msgEndRef = useRef(null);
  const observRef = useRef(null);
  const [comment, setComment] = useState('');
  const [panel, setPanel] = useState('details');
  const [newValues, setNewValues] = useState({ result: task.result, due_date: task.due_date, findings: '', observations: "" });
  const [isModified, setIsModified] = useState(false)
  const [isFromAction, setIsFromAction] = useState('');

  const handleDownloadEvidence = (e, evidence) => {
    e.preventDefault();
    if (evidence.evidence_url) {
      saveAs('https:' + evidence.evidence_url, evidence.document_file_name)
    }
  }
  const canChangeResponsible = () => {
    let canEdit = false, user = props.user;
    if (
      (task.creator_id === user.id) ||
      (task.supervisor_id === user.id) ||
      (task.accountable_id === user.id) ||
      (task.assignee_id === user.id)
    ) {
      canEdit = true
    }
    return canEdit;
  }
  const onEvidenceFile = (e => {
    if (e.target.files.length == 1) {
      props.attachTaskEvidence(e.target.files[0])
    }
  });
  useEffect(() => {
    if (!isCommentAdded && msgEndRef.current) {
      msgEndRef.current?.scrollBy({ behavior: "smooth", top: msgEndRef.current.scrollHeight + 100 })
    }
  }, [isCommentAdded]);
  useEffect(() => {
    if (isObserveAdded && observRef.current) {
      observRef.current?.scrollBy({ behavior: "smooth", top: observRef.current.scrollHeight + 50 })
      setTimeout(() => props.updateIsObserveAdded(false), 2000)
    }
  }, [isObserveAdded])
  const handleTaskPin = () => {
    const toggle = !Boolean(task.pin_task);
    const popup = {
      message: toggle ? 'Task Pinned Successfully' : 'Task Unpinned Successfully',
      submessage: toggle ? 'This Task will be sorted at the top of your Tasklist' : 'This Task will NOT be sorted anymore at the top of your Tasklist'
    }
    props.updateTask({ pin_task: toggle }, popup)
  }
  const isEditable = () => {
    const { task, user } = props
    if (((!Boolean(task.asmt_cntrl_id) && task.creator.id === user.id) || (task.assignee_id === user.id)) && !props.isResponsible) {
      return true;
    }
    return true
  }
  const handleClose = () => {
    if (comment.length > 0) {
      setIsModified(true);
      return false
    }
    return true;
  }
  const handleAdditionalParam = (e) => {
    const { name, value } = e.target;
    setNewValues((_) => {
      return { ..._, [name]: value };
    })
  }
  const handleSaveRisk = (e) => {
    const body = {}, params = {};
    if (newValues && newValues.result.id !== task.result) {
      body.result = newValues.result.id;
    }
    if (newValues.observations) {
      params.observations = newValues.observations;
    }
    if (newValues.findings) {
      params.findings = newValues.findings;
    }
    if (Object.keys(params).length > 0) {
      body.task_addnl_attr_id = "create";
      body.task_addnl_attrs = params;
      setNewValues((_) => ({ ..._, findings: '', observations: '' }))
    }
    if (Object.keys(body).length > 0) {
      props.updateTask(body)
    }
  }
  const getCardTitle = () => {
    let title = 'Details';
    if (props.user && task.assignee && task.assignee.ent_org) {
      if (props.user.ent_org.id !== task.assignee.ent_org.id) {
        title = task.assignee.ent_org.brandname;
      }
    }
    return title;
  }
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        className='dialog view-task-dialog'
        classes={{ paper: 'view-task-paper' }}
        BackdropProps={{
          onClick: () => {
            if (comment.length === 0) {
              setIsModified(true);
              props.onClose();
            }
          },
        }}
      >
        {
          props.isTaskFetched ?
            <div className='col container'>
              <div className='h7 exo2'>{task.assessmentPath}</div>
              <Grid container className='info-row' columnSpacing={4} >
                <Grid item lg={8} md={8} sm={12} xs={12} className='left'>
                  <div className={`h7  flex-ctr`}><span>{task.task_id}</span> <span className={`h7  flex-ctr priority ${String(task.priority).toLowerCase()}`}>{task.priority}</span></div>
                  <p className='h3 rmedimum name exo2'>
                    {task.asmt_cntrl ? `${task.asmt_cntrl.title} ${task.asmt_cntrl.txt}` : task.name}
                  </p>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} className='right'>
                  <div className='row btn-end' style={{ height: '100%' }}>
                    {
                      isEditable() &&
                      <Button
                        text='Edit'
                        color='#1177CC'
                        className='btn'
                        onClick={(e) => {
                          e.stopPropagation()
                          if (handleClose()) {
                            props.onEdit && props.onEdit();
                          } else {
                            setIsFromAction('edit')
                          }
                        }}
                      />
                    }
                    <Button
                      text='Close'
                      color='transparent'
                      className='h6 btn-close'
                      endIcon={<Close fontSize='small' sx={{ color: '#FF3333' }} />}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (handleClose()) {
                          props.onClose && props.onClose();
                        } else {
                          setIsFromAction('delete')
                        }
                      }} />
                    {/* <Button
                      text='Close'
                      color='#FF3333'
                      className='btn'
                      onClick={(e) => {

                      }}
                    /> */}
                  </div>
                </Grid>
                <AppErrorBoundry>
                  <Grid item lg={8} md={8} sm={12} xs={12} className='left'>
                    <Grid container columnSpacing={2}>
                      <TaskInfo
                        lg={6} md={6} sm={12} xs={12}
                        label='Control Description'
                        description={task.desc}
                      />
                      <TaskInfo
                        lg={6} md={6} sm={12} xs={12}
                        label='Evidence(s) Expected'
                        description={task.evidence_expected}
                      />
                      <TaskInfo
                        lg={12} md={12} sm={12} xs={12}
                        label='External Link(s)'
                        description={task.external_links}
                      />
                    </Grid>
                    {
                      Boolean(task.asmt_cntrl_id) &&
                      <Grid container columnSpacing={2} classes={{ root: 'activity-row' }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} classes={{ root: 'title' }}>
                          <label className='h7 exo2 label'>History</label>
                        </Grid>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                          <div className='chat-section'>
                            <label className='h6 exo2 heading'>Comments</label>
                            <div className='col content flex-full'>
                              {
                                Boolean(task.asmt_cntrl) && Array.isArray(task.asmt_cntrl.comment_list) && task.asmt_cntrl.comment_list.length > 0 ?
                                  task.asmt_cntrl.comment_list.map((comment) => {
                                    return (
                                      <Comment comment={comment} key={comment.id} />
                                    )
                                  })
                                  :
                                  <div className='col flex-ctr full'> No Comments</div>
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          <div className='chat-section'>
                            <label className='h6 exo2 heading'>Evidence</label>
                            <div className='col content flex-full'>
                              {
                                Boolean(task.asmt_cntrl) && Array.isArray(task.asmt_cntrl.evidence_list) && task.asmt_cntrl.evidence_list.length > 0 ?
                                  <>
                                    {
                                      task.asmt_cntrl.evidence_list.map((attachment) => {
                                        return (
                                          <Evidence
                                            attachment={attachment}
                                            key={attachment.id}
                                            onDownload={(e) => handleDownloadEvidence(e, attachment)} />
                                        )
                                      })
                                    }
                                  </>
                                  :
                                  <div className='col flex-ctr full'> No Evidence</div>
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    }
                    <Grid container columnSpacing={2} classes={{ root: 'activity-row' }}>
                      <Grid item lg={12} md={12} sm={12} xs={12} classes={{ root: 'title' }} >
                        <label className='h7 exo2 label'>Activity</label>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <div className='chat-section'>
                          <label className='h6 exo2 heading'>Comments</label>
                          <div className='col content flex-full' ref={msgEndRef}>
                            {
                              Array.isArray(task.comments) && task.comments.length > 0 ?
                                <>
                                  {
                                    task.comments.map((comment) => {
                                      return (
                                        <Comment comment={comment} key={comment.id} task={task} />
                                      )
                                    })
                                  }
                                </>
                                :
                                <div className='col flex-ctr full'> No Comments</div>
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <div className='chat-section'>
                          <label className='h6 exo2 heading'>Evidence</label>
                          <div className='col content flex-full'>
                            {
                              Array.isArray(task.attachments) && task.attachments.length > 0 ?
                                <>
                                  {
                                    task.attachments.map((attachment) => {
                                      return (
                                        <Evidence
                                          attachment={attachment}
                                          key={attachment.id}
                                          onDownload={(e) => handleDownloadEvidence(e, attachment)} />
                                      )
                                    })
                                  }
                                </>
                                :
                                <div className='col flex-ctr full'> No Evidence</div>
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <div className='row start'>
                          <UserAvatar user={props.user} />
                          <div className='row flex-full comment-input-sec'>
                            <textarea
                              rows={1}
                              className="col flex-full"
                              value={comment}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              onChange={(e) => {
                                setComment(e.target.value)
                              }}>
                            </textarea>
                            <Button
                              text='Comment'
                              color='#1177CC'
                              disabled={isCommentAdded}
                              loading={isCommentAdded}
                              endIcon={<Send fontSize='small' />}
                              onClick={(e) => {
                                e.stopPropagation()
                                if (comment.length > 0) {
                                  props.addTaskComment(comment);
                                  setComment("")
                                }
                              }}
                              className='h6' />
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <div className='row send-evidence'>
                          <div className='h6 col flex-full'>Select Evidence File</div>
                          <input
                            ref={evidenceRef}
                            type="file"
                            className='hide'
                            onChange={onEvidenceFile}
                          />
                          <Button
                            text='Attach'
                            color='#1177CC'
                            className='h6 attach'
                            disabled={isEvidence}
                            loading={isEvidence}
                            onClick={() => {
                              if (evidenceRef.current) {
                                evidenceRef.current.click();
                              }
                            }}
                            endIcon={(
                              <img src={Attachfile} alt='attach file' />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12} className='right'>
                    <div className='row space-btn status-row'>
                      <AutoCompleteSelect
                        className='status-select h42'
                        placeholder='Status'
                        label='Status'
                        labelClassName='exo2 rbold'
                        value={task.status}
                        options={props.statusList}
                        onChange={(e) => {
                          const status = e.target.value;
                          if (status && status.id) {
                            props.updateTask({ status: status.id });
                          }
                        }}
                      />
                      <Button
                        disableElevation={task.pin_task}
                        text={task.pin_task ? 'Task Pinned' : 'Pin Task'}
                        color={task.pin_task ? '#707070' : '#1177CC'}
                        className='h6 btn btn-pin no-wrap'
                        endIcon={(
                          <i className={`pin icon-pin`} />
                        )}
                        onClick={handleTaskPin}
                      />
                    </div>
                    <div className='col'>
                      <Accordion classes={{ root: 'task-accordion' }} expanded={panel === 'details'} onChange={() => setPanel(panel === 'details' ? '' : 'details')}>
                        <AccordionSummary
                          classes={{ root: 'accord-head' }}
                          expandIcon={<ExpandMore fontSize='large' />}
                          aria-controls="Details"
                          id="details-panel">
                          <span className='h42 exo2 rbold'>{getCardTitle()}</span>
                        </AccordionSummary>
                        <AccordionDetails >
                          <div className='col '>
                            <div className='row detals-row'>
                              <div className='col flex-full h7'>Department</div>
                              <span className='col flex-full val h6'>{task.department}</span>
                            </div>

                            <div className='row detals-row '>
                              <div className='col flex-full h7'>Supervisor</div>
                              <div className='row flex-full val no-wrap h6'>
                                {
                                  Boolean(props.supervisor) &&
                                  <AvatarCard small dark user={props.supervisor} />
                                }
                              </div>
                            </div>
                            <div className='row detals-row'>
                              <div className='col flex-full h7'>Target</div>
                              <DatePicker
                                name='targetDate'
                                disabled={props.isReviewer}
                                value={newValues.due_date} label='' className='val date h6 flex-full no-wrap'
                                onChange={(e) => {
                                  setNewValues((prev) => {
                                    return { ...prev, due_date: e.target.value }
                                  });
                                  props.updateTask({ ...task, due_date: moment(e.target.value).startOf('day').toISOString() }, { message: 'Target Date Updated' })
                                }} />
                            </div>
                            <div className='row detals-row '>
                              <div className='col flex-full h7'>Responsible</div>
                              <UserSelect
                                className='link val no-wrap h6'
                                disabled={!canChangeResponsible()}
                                value={props.responsible.findIndex((_u) => _u.id === task.assignee_id)}
                                users={props.responsible}
                                name='assignee'
                                onChange={(e) => {
                                  props.updateTask({ assignee_id: e.target.value.id }, { message: 'Responsible Updated' })
                                }} />
                            </div>
                            <div className='row detals-row'>
                              <div className='col flex-full h7'>Created</div>
                              <span className='col flex-full val h6 no-wrap'>{moment(task.created_at).format('ddd, DD-MMM-YY')}</span>
                            </div>
                            <div className='row detals-row'>
                              <div className='col flex-full h7'>Frequency</div>
                              <span className='col flex-full val h6 no-wrap'>{task.repetition_occurs}</span>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion classes={{ root: 'task-accordion risk' }} expanded={panel === 'risk'} onChange={() => setPanel(panel === 'risk' ? '' : 'risk')}>
                        <AccordionSummary
                          classes={{ root: 'accord-head' }}
                          expandIcon={<ExpandMore fontSize='large' />}
                          aria-controls="Details"
                          id="details-panel">
                          <span className='h42 exo2 rbold'>Risk</span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className='col risk'>
                            <div className='row end'>
                              <span className='h7 risk-label'>Associated Risk:<span className='risk-num'>{task.risk_score || 0}</span></span>
                            </div>
                            {
                              props.isResponsible ?
                                <InputContainer label='Result' className='result result-readonly'>
                                  <div className='h7'>{newValues.result || task.result} </div>
                                </InputContainer>

                                :
                                <AutoCompleteSelect
                                  value={newValues.result || task.result}
                                  containerClassName='result h7'
                                  label='Result'
                                  disabled={props.isResponsible}
                                  options={getTaskResultList(task)}
                                  onChange={(e) => setNewValues((prev) => {
                                    return { ...prev, result: e.target.value }
                                  })}
                                />
                            }
                            {
                              Boolean(task.asmt_cntrl_id) &&
                              <div className='activity'>
                                <div className='h7 exo2 label bold'>History</div>
                                <div className='content'>
                                  {
                                    task.observations &&
                                    <div className='col h6 observ'>
                                      <div>Observations</div>
                                      <div className='value'>{task.observations}</div>
                                    </div>
                                  }
                                  {
                                    task.findings &&
                                    <div className='col h6 observ'>
                                      <div>Findings</div>
                                      <div className='value'>{task.findings}</div>
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                            {
                              ((Boolean(task.task_addnl_attrs) && task.task_addnl_attrs.length > 0) || props.isReviewer) &&
                              <div className='activity'>
                                <div className='h7 exo2 label bold'>Activity</div>
                                <div className='content'>
                                  {
                                    (Array.isArray(task.task_addnl_attrs) && task.task_addnl_attrs.length > 0) &&
                                    <div className='observe-list custom-scroll' ref={observRef}>
                                      {
                                        task.task_addnl_attrs.map((adl, i) => {
                                          return (
                                            <div className={`col observ ${isObserveAdded && i === (task.task_addnl_attrs.length - 1) ? 'blink_me' : ''}`} key={adl.id}>
                                              <div className='row space-btn h8 bold'>
                                                <div className='bold'>{adl.creator ? `${adl.creator.firstname} ${adl.creator.lastname}` : ''}</div>
                                                <div className='bold date'>{moment(adl.updated_at).format('ddd, DD-MMM-YY HH:mm')}</div>
                                              </div>
                                              <div className='entry'>
                                                {
                                                  adl.observations &&
                                                  <>
                                                    <div className='h8'>Observations</div>
                                                    <div className='h7 value div'>{adl.observations}</div>
                                                  </>
                                                }
                                                {
                                                  adl.findings &&
                                                  <>
                                                    <div className='h8'>Findings</div>
                                                    <div className='h7 value'>{adl.findings}</div>
                                                  </>
                                                }
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  }
                                  {
                                    (props.isReviewer || props.isSupervisor) &&
                                    <div className='observ'>
                                      <Textarea
                                        className='h7' rows={2} name='observations' label='Observations'
                                        value={newValues.observations} onChange={handleAdditionalParam} />
                                      <Textarea
                                        className='h7' rows={2} name='findings' label='Findings'
                                        value={newValues.findings} onChange={handleAdditionalParam} />
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                            {
                              !props.isResponsible &&
                              <div className='col end'>
                                <Button
                                  className='save'
                                  disabled={
                                    (newValues.result === task.result && newValues.observations === '' && newValues.findings === '')
                                  }
                                  text='Save'
                                  color='#1177CC'
                                  onClick={handleSaveRisk}
                                  endIcon={<Save fontSize='large' />} />
                              </div>
                            }
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                </AppErrorBoundry>
              </Grid>
            </div>
            :
            <div className='col flex-ctr fetching'>
              <CircularProgress />
            </div>
        }

      </Dialog>
      {
        isModified &&
        <ConfirmationBox
          btnCancel={
            {
              text: 'Close', variant: 'text', className: 'h5 close', color: '#FFF',
              onClick: () => {
                setIsModified(false)
                if (isFromAction === 'edit') {
                  props.onEdit && props.onEdit();
                } else if (isFromAction === 'delete') {
                  props.onClose && props.onClose();
                }
              }
            }
          }
          onOk={() => {
            setIsModified(false);
            setIsFromAction('')
          }}
          btnOK='Go Back'
          className='task-dialog-confirmation'
          titleClassName='title'
          subtitleClassName='subtitle'
          title='Data will not be saved'
          subtitle='Comment that you have entered is not submitted, Exiting this screen will not save the comment'
        />
      }
    </>
  )
})

