import React, { useEffect, useRef, useState, } from 'react';
import { Table, TableHead, TableCell, TableBody, TableRow, PaginationItem, Pagination, TableSortLabel, CircularProgress } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import SortIcon from "../../assets/images/icon-sort.svg";
import { AvatarCard } from "../../components";
import { connect } from 'react-redux';
import { getSortModel, getTotalPages, updateTask, setSortModel, isReviewer } from "../../store";

const TableColumns = [
  { header: '', width: '2%', attribute: 'pin_task' },
  { header: 'Task ID', width: '9%', attribute: 'task_id'},
  { header: 'Priority', width: '7%', attribute: 'priorityText', align: 'left' },
  { header: 'Task', width: '32%', attribute: 'shortenedTitle', align: 'justify', wrap : true},
  { header: 'Responsible', width: '18%', attribute: 'assignee', align: 'left' },
  { header: 'Department', width: '8%', attribute: 'departmentText', align: 'left' },
  { header: 'Target', width: '12%', attribute: 'targetDate' },
  { header: 'Frequency', width: '6%', attribute: 'repeationText' },
  { header: 'Status', width: '6%', attribute: 'status' },
];
const ReviewerColumns = [
  { header: 'Result', width: '18%', attribute: 'result' },
]
const getColumns = (iIsReviewer) => {
  let columns = TableColumns.map((_) => {
    return { ..._ }
  })
  if (iIsReviewer) {
    columns[3].width = '26%';
    columns.push({ ...ReviewerColumns[0], width: '17%'})
  }
  return columns;
}
const Cell = (props) => {
  const { attribute, cellData, align } = props;
  return (
    <TableCell onClick={props.onClick} classes={{ root: `h6 ${props.wrap ? '' : 'no-wrap'}` }} className={` task-cell ${align || ''}`}>
      {
        attribute === 'pin_task' ?
          <i className={`pin icon-pin ${cellData ? 'pinned' : ''}`} onClick={props.togglePin} />
          : attribute === 'priorityText' ?
            <div className={`col flex-ctr priority ${String(cellData || '').toLowerCase()}`}>
              <span>{cellData || 'default'}</span>
            </div>
            : attribute === 'assignee' && Boolean(cellData) ?
              <div className={`row ${align === 'left' ? 'h-start' : 'flex-ctr'}`}>
                <AvatarCard small user={cellData} fontClassName='h6' />
              </div>
              :
              attribute === 'status' ?
                <div className='col flex-ctr'>
                  <div className={`col flex-ctr status ${String(cellData).toLowerCase()}`}>
                    <span>{cellData}</span>
                  </div>
                </div>
                :

                  cellData
      }
    </TableCell>
  )
};

const Row = (props) => {
  const Columns = getColumns(props.isReviewer)
  const { task } = props;
  return (
    <TableRow className={`data-row task-row ${task.pin_task && 'pinned'}`}>
      {
        Columns.map((column) => {
          const val = task[column.attribute];
          return (
            <Cell
              key={column.attribute}
              onClick={props.onClick}
              cellData={val}
              pin_task={column.attribute}
              sortModel={props.sortModel}
              {...column}
              togglePin={(e) => {
                e.stopPropagation()
                const toggle = !Boolean(task.pin_task);
                const popup = {
                  message: toggle ? 'Task Pinned Successfully' : 'Task Unpinned Successfully',
                  submessage: toggle ? 'This Task will be sorted at the top of your Tasklist' : 'This Task will NOT be sorted anymore at the top of your Tasklist'
                }
                props.updateTask({ pin_task: toggle }, popup)
              }}
            />
          )
        })
      }
    </TableRow>
  )
};
const mapDispatchToProps = (dispatch, ownProps) => {
  let task = ownProps.task;
  return {
    updateTask: (values, message, submsg) => dispatch(updateTask(values, task.id, message, submsg))
  }
}
const TaskRow = connect(null, mapDispatchToProps)(Row)
const TaskTable = (props) => {
  const {page} = props;
  const Columns = getColumns(props.isReviewer);
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef();
  const onPageChange = (event, value) => {
    // setPage(value)
    props.onPageChange && props.onPageChange(value)
  }
  useEffect(() => {
    if (tableRef && tableRef.current) {
      setTableHeight(tableRef.current.clientHeight - 52);
    }
  }, [])
  const handleSort = (column) => {
    let sortModel = { attribute: column.attribute, dir: 'asc' };
    if (props.sortModel && props.sortModel.attribute === column.attribute) {
      if (props.sortModel.dir === 'asc') {
        sortModel.dir = 'desc';
      } else if (props.sortModel.dir === 'desc') {
        sortModel = null;
      }
    }
    props.setSortModel(sortModel)
  }
  return (
    <div className='col task-table space-btn flex-full' ref={tableRef}>
      {
        !Boolean(props.tasks) ?
          <CircularProgress />
          : Array.isArray(props.tasks) && props.tasks.length === 0 ?
            <div className='col full flex-ctr'>
              <div className='h5'>No Tasks</div>
            </div>
            :
            <>
              <div className='col flex-full task-table-container' style={{ width: '100%', maxHeight: tableHeight }}  >
                <Table aria-label="simple table" classes={{ root: '' }} stickyHeader>
                  <TableHead className='header-row'>
                    <TableRow>
                      {
                        Columns.map((column) => {
                          return (
                            <TableCell
                              width={column.width}
                              key={column.attribute}
                              classes={{ root: `h7 head-cell ${column.align || ''}` }}
                            >
                              <TableSortLabel
                                active={false}
                                direction={'asc'}
                                classes={{ root: 'row' }}
                                IconComponent={() => {
                                  return (
                                    Boolean(column.header) ?
                                      props.sortModel && props.sortModel.attribute === column.attribute ?
                                        props.sortModel.dir === 'asc' ?
                                          <ArrowDropUp sx={{ fontSize: 14, color: '#000000' }} /> :
                                          <ArrowDropDown sx={{ fontSize: 14, color: '#000000' }} />
                                        :
                                        <img src={SortIcon} className='sort-icon' alt='sort-icon' />
                                      : null
                                  )
                                }}
                                onClick={() => handleSort(column)}>
                                {column.header}
                              </TableSortLabel>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      props.tasks.map((task, index) => {
                        return (
                          <TaskRow key={index} task={task} isReviewer={props.isReviewer} onClick={() => props.onViewTask(task)} />
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </div>
              <Pagination
                count={props.totalPages}
                shape="rounded"
                page={page}
                boundaryCount={2}
                classes={{
                  root: 'col flex-ctr task-pg',

                }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    classes={{ previousNext: 'h2', page: 'h6' }}
                  />
                )}
                onChange={onPageChange}
              />
            </>
      }
    </div>
  )
}
const mapStateToPropsTable = (state) => {
  return {
    totalPages: getTotalPages(state),
    sortModel: getSortModel(state),
    isReviewer: isReviewer(state),
  }
}
const mapDispatchToPropsTable = (dispatch) => {
  return {
    setSortModel: (model) => dispatch(setSortModel(model))
  }
}
export default connect(mapStateToPropsTable, mapDispatchToPropsTable)(TaskTable);
