import { SagaRegistry } from "../SagaRegistry";
import { requestHandler, setAlertAndLoading, getMultipartHeader, setConfimationYield } from "../common";
import { takeEvery, put, select } from 'redux-saga/effects';
import {
  GET_API_KEY, FETCH_DEFINED_LIST, TASKS_PER_PAGE, CREATE_TASK, FETCH_TASKS, FETCH_TASK, UPDATE_TASK, DELETE_TASK, LIST_ACTIVITY,
  SEARCH_TASKS, ADD_TASK_COMMENT, ATTACH_TASK_EVIDENCE, FETCH_DEFINED_LIST_SUCCESS, CREATE_TASK_SUCCESS, LIST_CUSTOMTYPES,
  FETCH_TASKS_SUCCESS, FETCH_TASK_SUCCESS, UPDATE_TASK_SUCCESS, DELETE_TASK_SUCCESS, ADD_TASK_COMMENT_SUCCESS,
  ATTACH_TASK_EVIDENCE_SUCCESS, FETCH_DASHBOARD, FETCH_DASHBOARD_SUCCESS, STORE_NAME, FILTER_TASKS, ADD_CUSTOM_TYPE,
  BULK_UPLOAD_TASK, BULK_UPLOAD_TASK_SUCCESS, FETCH_BULK_UPLOAD, FETCH_BULK_UPLOAD_SUCCESS, DEPT_RESPONSIBLE, IS_OBSERV_ADDED,
  DEPT_RESPONSIBLE_SUCCESS, NOTIF_FREQ_LIST, NOTIF_FREQ_LIST_SUCCESS, UPDATE_NOTIF_FREQ, UPDATE_NOTIF_FREQ_SUCCESS, LIST_MAPPED_ORGS,
  FETCH_MAPPED_ORGS, OPEN_TASK, OPEN_TASK_SUCCESS
} from "./actionsType";
import { axios } from "../../utils";
import { KEY_NM_API, KEY_NM_GUID, VALIDATE_TOKEN } from "../common";

const TASK_PATH = '/tasks/'

const getDashboardFormat = (values) => {
  let overall = { department: 'OVERALL' };
  let data = Object.keys(values).map((key) => {
    var _values = {}
    for (const _key in values[key]) {
      if (Object.hasOwnProperty.call(values[key], _key)) {
        let val = values[key][_key], valKey = _key.replaceAll(" ", '');
        if (!overall[valKey]) overall[valKey] = 0;
        _values[valKey] = val;
        overall[valKey] += Number(val);
      }
    }
    return {
      department: key,
      ..._values
    }
  })
  return [overall, ...data];
}
const getSourceTasksMap = (values) => {
  return Object.keys(values).map((key) => {
    let asmt;
    try {
      asmt = JSON.parse(key)
    } catch (error) {
      asmt = [key]
    }
    const id = asmt[1] || asmt[0], label = asmt[0] || asmt[1], list = values[key];
    let rows = [];
    const overall = Object.keys(list).reduce((initial, value, index, arrrr) => {
      rows.push({ label: value, ...list[value] })
      return initial + list[value].total;
    }, 0);
    return {
      id: id,
      label: label,
      rows: rows,
      overall: overall
    }
  });
}
function* getApiKey({ payload }) {
  yield requestHandler(function* () {
    const guid = payload.guid;
    let res = yield axios.get(TASK_PATH + 'get_api_key?guid=' + guid);
    if (res.api_key) {
      localStorage.setItem(KEY_NM_API, res.api_key);
      localStorage.setItem(KEY_NM_GUID, guid);
      yield put({ type: VALIDATE_TOKEN });
    }
  })
}
function* fetchDefinedList({ payload }) {
  yield requestHandler(function* () {
    let type = payload.type;
    const mapType = (type === LIST_CUSTOMTYPES) ? LIST_ACTIVITY : type;
    let path = TASK_PATH + type;
    if (type === LIST_MAPPED_ORGS) {
      path = '/consoles/' + type
    }
    let res = yield axios.get(path);
    let list = res[mapType] ? res[mapType] : (Array.isArray(res) ? res : []);
    list = list.map((_) => {
      if (Array.isArray(_)) {
        return { label: _[0], id: _[1], guid: _[2] }
      } else if (typeof _ === "object") {
        if (!_.label) {
          _.label = Boolean(_.firstname) ? `${_.firstname} ${_.lastname}` : (_.title || '');
        }
        return _;
      } else {
        return { label: _, id: _ }
      }
    })
    yield put({ type: FETCH_DEFINED_LIST_SUCCESS, payload: { [type]: list } })
  })
}
function* fetchMappedOrgs() {
  yield requestHandler(function* () {
    const type = LIST_MAPPED_ORGS
    let res = yield axios.get('/consoles/' + type);
    let list = res[type] ? res[type] : (Array.isArray(res) ? res : []);
    list = list.map((_) => {
      if (!_.label) {
        _.label = _.brandname
      }
      return _;
    })
    yield put({ type: FETCH_DEFINED_LIST_SUCCESS, payload: { [type]: list } })
  })
}
function* createNewTask({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    const res = yield axios.post(TASK_PATH, payload);
    yield setAlertAndLoading(false);
    yield setConfimationYield({
      title: "Task Successfully created",
      subtitle: 'Task has been successfully created with the details provided by you.'
    });
    yield put({ type: CREATE_TASK_SUCCESS, payload: res })
    if (res.activity_type) {
      yield put({ type: FETCH_DASHBOARD })
      yield put({ type: ADD_CUSTOM_TYPE, payload: res.activity_type })
    }
  })
}
function* fetchAllTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + `?page=${payload.pageNo || 1}&per_page=${TASKS_PER_PAGE}`);
    yield put({ type: FETCH_TASKS_SUCCESS, payload: res })
  })
}
function* fetchDepartmentAuditee({ payload }) {
  yield requestHandler(function* () {
    const { department, orgId, currentUserOrg } = payload;
    let responsible;
    if (currentUserOrg === orgId) {
      responsible = yield axios.post(TASK_PATH + 'responsible_user_list', { department_name: department });
    } else {
      const query = `mapped_org_id=${orgId}${department ? `&department_name=${department}` : ''}`
      responsible = yield axios.get('/consoles/get_mapped_user_details?' + query);
      responsible = responsible.mapped_user_list
    }
    if (!Array.isArray(responsible)) {
      responsible = []
    }
    responsible = responsible.map((_r) => {
      if (Array.isArray(_r)) {
        return { label: _r[0], id: _r[1], guid: _r[2] }
      } else {
        return { label: `${_r.firstname} ${_r.lastname}`, id: _r.id, guid: _r.guid }
      }
    })
    yield put({ type: DEPT_RESPONSIBLE_SUCCESS, payload: { department: department, responsible: responsible, orgId } })
  })
}
function* fetchTask({ payload }) {
  yield requestHandler(function* () {
    let { task } = payload, currentTask;
    if (task) {
      currentTask = task;
    } else {
      currentTask = yield select((state) => state[STORE_NAME].tasks.find(_t => _t.id === payload.taskId));
    }
    const res = yield axios.get(TASK_PATH + payload.taskId);
    const list = yield axios.post(TASK_PATH + 'asmt_risk_result_list', { task: { asmt_id: currentTask.asmt_id || currentTask.activity_type } });
    const history = yield axios.get(TASK_PATH + 'audit_evidences?id=' + payload.taskId);
    const task_addnl_attrs = yield axios.get(TASK_PATH + 'fetch_task_addnl_params?id=' + payload.taskId);
    const updateTask = { ...res, ...list, asmt_cntrl: history.asmt_cntrl, ...(task_addnl_attrs || {}) }
    yield put({ type: UPDATE_TASK_SUCCESS, payload: updateTask });
    yield put({ type: FETCH_TASK_SUCCESS })
  })
}
function* openTask({ payload }) {
  yield requestHandler(function* () {
    const { taskGuid } = payload;
    let task = yield axios.get(`${TASK_PATH}get_task_by_guid?guid=${taskGuid}`);
    if (task) {
      yield put({ type: OPEN_TASK_SUCCESS, payload: { task } })
    }
  })
}
function* updateTask({ payload }) {
  const { taskId, updatedVal, popup } = payload
  yield requestHandler(function* () {
    let fetch = true
    if (updatedVal.pin_task === true) {
      const p = yield axios.get(TASK_PATH + 'pin_task_count');
      if (p.pin_task_count >= 5) {
        yield setConfimationYield({
          title: 'Pin limit reached',
          subtitle: 'You have already pinned maximum allowed tasks',
          btnOK: 'Go Back'
        });
        fetch = false;
      }
    }
    if (fetch) {
      const isObserveAdded = Boolean(updatedVal.task_addnl_attrs);
      const res = yield axios.put(TASK_PATH + taskId, { task: updatedVal });
      yield put({ type: UPDATE_TASK_SUCCESS, payload: res });
      yield put({ type: IS_OBSERV_ADDED, payload: isObserveAdded });
      yield setAlertAndLoading(false);
      yield setConfimationYield({
        title: Boolean(popup && popup.message) ? popup.message : 'Task Successfully updated',
        subtitle: Boolean(popup && popup.submessage) ? popup.submessage : 'The changes made to the Task have been saved successfully!'
      });
    }
  })
}
function* deleteTask({ payload }) {
  const { taskId } = payload
  yield requestHandler(function* () {
    const res = yield axios.delete(TASK_PATH + taskId);
    yield put({ type: DELETE_TASK_SUCCESS, payload: res })
  })
}
function* addTaskComment({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + 'comment', payload);
    yield put({ type: ADD_TASK_COMMENT_SUCCESS })
    yield put({ type: UPDATE_TASK_SUCCESS, payload: res });
    yield setAlertAndLoading(false, { message: 'Comment added Successfully.' });
  })
}
function* attachTaskEvidence({ payload }) {
  const { formData } = payload;
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + 'evidence', formData, getMultipartHeader());
    yield put({ type: ATTACH_TASK_EVIDENCE_SUCCESS, payload: res });
    yield put({ type: UPDATE_TASK_SUCCESS, payload: res });
    yield setAlertAndLoading(false, { message: 'Evidence attached Successfully.' });
  })
}
function* filterTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + `search/?page=${payload.pageNo || 1}&per_page=${TASKS_PER_PAGE}`, { task: payload.filter });
    yield put({ type: FETCH_TASKS_SUCCESS, payload: res })
  })
}
function* searchTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + `search_str?page=${payload.pageNo || 1}&per_page=${TASKS_PER_PAGE}`, { search_str: payload.searchText });
    yield put({ type: FETCH_TASKS_SUCCESS, payload: res })
  })
}
function* fetchDashBoardContent() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + `dashboard`);
    let deptVsPriority = [], deptStatus = [], statusVsPriorty = [], 
        asmtVsPriorty = [], actvVsPriority = [], incVsPriority = [];
    if (res.dept_priority_list) {
      deptVsPriority = getDashboardFormat(res.dept_priority_list);
    }
    if (res.dept_status_list) {
      deptStatus = getDashboardFormat(res.dept_status_list);
    }
    if (res.status_priority_list) {
      statusVsPriorty = getDashboardFormat(res.status_priority_list);
    }
    if (res.asmt_priority_list) {
      asmtVsPriorty = getSourceTasksMap(res.asmt_priority_list);
    }
    if (res.activity_type_priority_list) {
      actvVsPriority = getSourceTasksMap(res.activity_type_priority_list);
    }
    if (res.incident_task_list) {
      incVsPriority = getSourceTasksMap(res.incident_task_list);
    }
    yield put({ type: FETCH_DASHBOARD_SUCCESS, payload: { deptVsPriority, deptStatus, statusVsPriorty, asmtVsPriorty, actvVsPriority, incVsPriority } })
  })
}
function* bulkUploadTasks({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + 'bulk_task_upload', payload, { 'Content-Type': "multipart/form-data" });
    yield put({ type: BULK_UPLOAD_TASK_SUCCESS, payload: res })
  })
}
function* fetchBulkUploads() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + 'file_uploads');
    yield put({ type: FETCH_BULK_UPLOAD_SUCCESS, payload: res })
  })
}
function* fetchNotificationFreqList() {
  yield requestHandler(function* () {
    const res = yield axios.get(TASK_PATH + 'notification_frequency_list');
    yield put({ type: NOTIF_FREQ_LIST_SUCCESS, payload: res.noti_frequency_list || [] })
  })
}
function* updateNotifFreq({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post(TASK_PATH + 'update_noti_freq', { task: { notification_frequency: payload } });
    yield put({ type: UPDATE_NOTIF_FREQ_SUCCESS, payload: res.noti_frequency_list || [] });
    yield setAlertAndLoading(false, { message: 'Settings Updated Successfully' });
  })
}
SagaRegistry.register(function* () {
  yield takeEvery(GET_API_KEY, getApiKey);
  yield takeEvery(FETCH_DEFINED_LIST, fetchDefinedList);
  yield takeEvery(FETCH_MAPPED_ORGS, fetchMappedOrgs);
  yield takeEvery(CREATE_TASK, createNewTask);
  yield takeEvery(FETCH_TASKS, fetchAllTasks);
  yield takeEvery(FETCH_TASK, fetchTask);
  yield takeEvery(OPEN_TASK, openTask);
  yield takeEvery(UPDATE_TASK, updateTask);
  yield takeEvery(DELETE_TASK, deleteTask);
  yield takeEvery(ADD_TASK_COMMENT, addTaskComment);
  yield takeEvery(ATTACH_TASK_EVIDENCE, attachTaskEvidence);
  yield takeEvery(FILTER_TASKS, filterTasks);
  yield takeEvery(SEARCH_TASKS, searchTasks);
  yield takeEvery(FETCH_DASHBOARD, fetchDashBoardContent);
  yield takeEvery(BULK_UPLOAD_TASK, bulkUploadTasks);
  yield takeEvery(FETCH_BULK_UPLOAD, fetchBulkUploads);
  yield takeEvery(DEPT_RESPONSIBLE, fetchDepartmentAuditee);
  yield takeEvery(NOTIF_FREQ_LIST, fetchNotificationFreqList);
  yield takeEvery(UPDATE_NOTIF_FREQ, updateNotifFreq);
})