import React from 'react';
import { Dialog } from '@mui/material';
import { Button } from "../Button";

export const ConfirmationBox = (props) => {
  return (
    <Dialog open className='dialog ' classes={{ paper: `alert-dialog ${props.className || ''}` }}>
      <div className='col' >
        <strong className={`h1 exo2 rmedium heading ${props.titleClassName || ''}`}>{props.title}</strong>
        <strong className={`h42 ${props.subtitleClassName || ''}`}>{props.subtitle}</strong>
        <div className='col flex-ctr action'>
          <Button text={props.btnOK || 'Okay'} className='h5' color='#1177CC' onClick={props.onOk} />
          {
            Boolean(props.btnCancel) &&
            <Button
              color={props.btnCancel.color}
              className={props.btnCancel.className}
              text={props.btnCancel.text || 'Cancel'}
              onClick={props.btnCancel.onClick}
              variant={props.btnCancel.variant} />
          }
        </div>
      </div>
    </Dialog>
  )
}
