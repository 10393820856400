import {
  STORE_NAME, GET_API_KEY, FETCH_DEFINED_LIST, CREATE_TASK, FETCH_TASKS, FETCH_TASK, UPDATE_TASK, DELETE_TASK, ADD_TASK_COMMENT,
  ATTACH_TASK_EVIDENCE, LIST_ACTIVITY, LIST_CUSTOMTYPES, LIST_ASSEMENT, LIST_ACCOUNTABLE, LIST_ASSIGNEE, LIST_DEPT, LIST_PRIORITY,
  LIST_REPETATIONS, SEARCH_TASKS, TASKS_PER_PAGE, FETCH_DASHBOARD, SET_MAX_PIN_COUNT, BULK_UPLOAD_TASK, FILTER_TASKS,
  FETCH_BULK_UPLOAD, LIST_STATUS, SET_SEARCH_TEXT, SET_SORT_MODEL, DEPT_RESPONSIBLE, LIST_SUPERVISOR, NOTIF_FREQ_LIST,
  UPDATE_NOTIF_FREQ, LIST_MAPPED_ORGS, FETCH_MAPPED_ORGS, OPEN_TASK, IS_OBSERV_ADDED, LIST_INCIDENTS, RESET_TASK_CREATE_STATUS
} from "./actionsType";
import "./middleware";
import "./reducer";
import moment from 'moment';
import { encode, decode } from "base-64";
import { getUser } from "../user"
import { axios } from "../../utils";

const DefaultTaskResult = [
  { id: 'Implemented', label: "Implemented" },
  { id: 'Pending', label: "Pending" }
]

const DueDateMatch = { "Critical": 15, "High": 30, "Medium": 45, "Low": 90, "Minimal": 180, "Default": 45 }

export const getDueDateMatch = () => {
  return DueDateMatch
}
export const getTaskDueDate = (task) => {
  const isRepeation = task && task.repetition_occurs;
  let due_date = null;
  if (task.due_date) {
    due_date = task.due_date
  } else {
    due_date = isRepeation ? moment(task.created_at).add(DueDateMatch[task.priority], 'days').toISOString() : task.end_date
  }
  return due_date
}
export const getApiKey = (guid) => {
  return { type: GET_API_KEY, payload: { guid: guid } }
}
export const fetchTaskDepartment = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_DEPT } }
}
export const fetchTaskPriorities = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_PRIORITY } }
}
export const fetchTaskActivities = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_ACTIVITY } }
}
export const fetchTaskCustomTypeSources = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_CUSTOMTYPES } }
}
export const fetchTaskAssements = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_ASSEMENT } }
}
export const fetchIncidents = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_INCIDENTS } }
}
export const fetchTaskAccoutable = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_ACCOUNTABLE } }
}
export const fetchTaskAssignee = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_ASSIGNEE } }
}
export const fetchTaskSupervisor = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_SUPERVISOR } }
}
export const fetchTaskRepeationOccurList = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_REPETATIONS } }
}
export const fetchTaskStatus = () => {
  return { type: FETCH_DEFINED_LIST, payload: { type: LIST_STATUS } }
}
export const fetchMappedOrgs = () => {
  return { type: FETCH_MAPPED_ORGS, payload: { type: LIST_MAPPED_ORGS } }
}
export const createNewTask = (task) => {
  return { type: CREATE_TASK, payload: task }
}
export const openTask = (taskGuid) => {
  return { type: OPEN_TASK, payload: { taskGuid } }
}
export const fetchTasks = (pageNo) => {
  return { type: FETCH_TASKS, payload: { pageNo } }
}
export const searchTasks = (pageNo, searchText) => {
  return { type: SEARCH_TASKS, payload: { searchText, pageNo } }
}
export const fetchFilterTasks = (pageNo, filter) => {
  return { type: FILTER_TASKS, payload: { filter, pageNo } }
}
export const fetchTask = (taskId) => {
  return { type: FETCH_TASK, payload: { taskId } }
}
export const updateTask = (updatedVal, taskId, popup) => {
  return { type: UPDATE_TASK, payload: { updatedVal, taskId, popup } }
}
export const deleteTask = (taskId) => {
  return { type: DELETE_TASK, payload: { taskId } }
}
export const addTaskComment = (taskId, comment) => {
  return { type: ADD_TASK_COMMENT, payload: { id: taskId, comment } }
}
export const setPinCountStatus = (status) => {
  return { type: SET_MAX_PIN_COUNT, payload: { status } }
}
export const setSearchText = (text) => {
  return { type: SET_SEARCH_TEXT, payload: text }
}
export const setSortModel = (model) => {
  return { type: SET_SORT_MODEL, payload: model }
}
export const uploadBulkTask = (file) => {
  return { type: BULK_UPLOAD_TASK, payload: file }
}
export const fetchBulkUploads = () => {
  return { type: FETCH_BULK_UPLOAD }
}
export const fetchNotifFreqList = () => {
  return { type: NOTIF_FREQ_LIST }
}
export const updateNotifFreq = (freq) => {
  return { type: UPDATE_NOTIF_FREQ, payload: freq }
}
export const fetchDepartmentAuditee = (department, orgId, currentUserOrg) => {
  return { type: DEPT_RESPONSIBLE, payload: { department, orgId, currentUserOrg } }
}
export const updateIsObserveAdded = (value) => {
  return { type: IS_OBSERV_ADDED, payload: value }
}
export const resetTaskStatus = () => {
  return { type: RESET_TASK_CREATE_STATUS }
}
export const getTaskResultList = (task) => {
  if (task.result_list) {
    return task.result_list.map((result) => {
      return { id: result, label: result }
    })
  }
  else {
    return DefaultTaskResult
  }
}
export const getDepartmentAuditee = (state, dept, orgId) => {
  const responsibles = state[STORE_NAME].responsibles;
  return (responsibles && responsibles[orgId] && Array.isArray(responsibles[orgId][dept])) ? responsibles[orgId][dept] : []
}
export const getDepartmentAuditeeById = (state, dept, id) => {
  let responsible = getDepartmentAuditee(state, dept);
  return responsible.find((_) => _.id === id)
}
export const getPinCountStatus = (state) => {
  return state[STORE_NAME].maxPinCountReached
}
export const attachTaskEvidence = (taskId, file) => {
  let formData = new FormData();
  formData.append('document', file);
  formData.append('id', taskId);
  return { type: ATTACH_TASK_EVIDENCE, payload: { taskId, formData } }
}
export const getDepratmentList = (state) => {
  return state[STORE_NAME][LIST_DEPT];
}
export const getProrityList = (state) => {
  return state[STORE_NAME][LIST_PRIORITY]
}
export const getRepeationOccurList = (state) => {
  return state[STORE_NAME][LIST_REPETATIONS]
}
export const getRepeationOccurByType = (state, type) => {
  const repeations = getRepeationOccurList(state)
  return (repeations || []).find((_) => {
    return _.id === type
  })
}
export const getCustomTypes = (state) => {
  const activityTypes = state[STORE_NAME][LIST_CUSTOMTYPES];
  if (activityTypes) {
    return activityTypes.map(_ => {
      return { ..._, group: 'Custom' }
    });
  }
  return null
}
export const getActivityTypes = (state) => {
  const activityTypes = state[STORE_NAME][LIST_ACTIVITY];
  if (activityTypes) {
    return activityTypes.map(_ => {
      return { ..._, group: 'Activity Types' }
    });
  }
  return null
}
const getAllAssements = (state) => {
  return state[STORE_NAME][LIST_ASSEMENT]
}
const getAllIncidents = (state) => {
  let incidents = state[STORE_NAME][LIST_INCIDENTS]
  if (Array.isArray(incidents) && incidents.length > 0) {
    incidents = incidents.map((_) => ({ ..._, group: 'Incidents' }))
  }
  return incidents;
}
export const getAllSource = (state) => {
  let sources = null;
  const activityTypes = getActivityTypes(state);
  const assessments = getAllAssements(state);
  const customTypes = getCustomTypes(state);
  const incidents = getAllIncidents(state);
  if (assessments) {
    sources = assessments.map(_ => {
      return { ..._, group: 'Auditor Assessments' }
    })
  }
  if (activityTypes) {
    sources = (sources || []).concat(activityTypes);
  }
  if (customTypes) {
    sources = (sources || []).concat(customTypes);
  }
  if (Array.isArray(incidents)) {
    sources = (sources || []).concat(incidents);
  }
  return sources;
}
export const getAllSourceWithTasks = (state) => {
  const activityTypes = getActivityTypesTasks(state);
  const customTypes = getCustomTypesTasks(state);
  let sources = getAssmentHasTask(state);
  let incidents = getAllIncidents(state);
  return (sources || []).concat(activityTypes || []).concat(customTypes || []).concat(incidents || []);
}
export const getAllSourceInGroup = (state, withTasks = false) => {
  let result = [];
  const activityTypes = withTasks ? getActivityTypesTasks(state) : getActivityTypes(state);
  const customTypes = withTasks ? getCustomTypesTasks(state) : getCustomTypes(state);
  const sources = withTasks ? getAssmentHasTask(state) : getAllAssements(state);
  let incidents = getAllIncidents(state);
  if (sources && sources.length > 0) {
    result.push({
      label: 'Audit',
      items: sources
    })
  }
  if (activityTypes && activityTypes.length > 0) {
    result.push({
      label: 'Activity',
      items: activityTypes
    })
  }
  if (customTypes && customTypes.length > 0) {
    result.push({
      label: 'Custom',
      items: customTypes
    })
  }
  if (incidents && incidents.length > 0) {
    result.push({
      label: 'Incidents',
      items: incidents
    })
  }
  return result;
}
export const getAccountableList = (state) => {
  return state[STORE_NAME][LIST_ACCOUNTABLE]
}
export const getAssigneeList = (state) => {
  return state[STORE_NAME][LIST_ASSIGNEE]
}
export const getMappedOrgs = (state, userOrg) => {
  const orgList = state[STORE_NAME][LIST_MAPPED_ORGS];
  let orgs
  if (orgList) {
    orgs = [...orgList]
    if (userOrg) {
      let index = orgs.findIndex((_) => _.id === userOrg.id);
      if (index === -1) {
        orgs.push({ id: userOrg.id, label: userOrg.brandname })
      }
    }
  }
  return orgs
}
export const getMappedOrgById = (state, id) => {
  const orgList = getMappedOrgs(state);
  let org;
  if (orgList) {
    org = orgList.find((_) => (_.id === id || _.guid === id));
  }
  return org
}
export const getMappedOrgUsers = (state, orgId) => {
  const responsibles = state[STORE_NAME].responsibles;
  let users = [];
  if (responsibles && responsibles[orgId]) {
    Object.values(responsibles[orgId]).forEach((_) => {
      users = [...users, ..._]
    })
    return users
  }
  return users
}
export const getSupervisorById = (state, id) => {
  let list = getSupervisors(state)
  return list && list.find((_item) => _item.id === id)
}
export const getSupervisors = (state, id) => {
  return state[STORE_NAME][LIST_SUPERVISOR];
}

export const isTaskCreated = (state) => {
  return state[STORE_NAME].createSuccess
}
export const getSortModel = (state) => {
  return state[STORE_NAME].sortModel;
}
const isTaskMatches = (_t, searchText) => {
  return (
    (_t.priorityText && String(_t.priorityText).toLowerCase().includes(searchText)) ||
    (_t.assignee && (String((_t.assignee.firstname || '') + ' ' + (_t.assignee.lastname || '')).toLowerCase().includes(searchText))) ||
    (_t.departmentText && String(_t.departmentText).toLowerCase().includes(searchText)) ||
    (_t.targetDate && String(_t.targetDate).toLowerCase().includes(searchText)) ||
    (_t.title && String(_t.title).toLowerCase().includes(searchText)) ||
    (_t.status && String(_t.status).toLowerCase().includes(searchText))
  )
}
const compareTasks = (valueA, valueB, direction) => {
  if (direction === 'asc') {
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  } else {
    return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
  }
}
export const getAllTasks = (state, shouldSort = false) => {
  let tasks = state[STORE_NAME].tasks, filtered;
  let searchText = state[STORE_NAME].seachText;
  if (Array.isArray(tasks)) {
    filtered = [];
    let sort = getSortModel(state);
    sort = shouldSort ? sort : null;
    if (searchText) {
      searchText = String(searchText).toLowerCase();
    }
    const user = getUser(state);
    tasks.forEach((_task) => {
      let _t = JSON.parse(JSON.stringify(_task));
      let assignee = getAssigneeById(state, _t.assignee_id, _t.department);
      let title = _t.asmt_cntrl ? `${_t.asmt_cntrl.title} ${_t.asmt_cntrl.txt || ''}` : `${_t.name} ${_t.desc || ''}`;
      _t.priorityText = getPriorityById(state, _t.priority, true);
      _t.assignee = assignee ? assignee : (_t.assignee_id === user.id ? user : (_task.assignee || null));
      // _t.accountable = getAccountableById(state, _t.accountable_id);
      _t.departmentText = getDepartmentById(state, _t.department, true);
      _t.targetDate = moment(getTaskDueDate(_t)).format('ddd, DD-MMM-YY');
      _t.status = _t.status || 'Pending';
      _t.repeationText = getRepeationOccurByType(state, _t.repetition_occurs);
      _t.repeationText = (typeof _t.repeationText === "object") ? _t.repeationText.label : (_t.repeationText || 'Once')
      _t.shortenedTitle = title;
      if (title.length > 100) {
        _t.shortenedTitle = String(title).substring(0, 99) + '...'
      }
      if (!searchText || (searchText && isTaskMatches(_t, searchText))) {
        filtered.push(_t)
      }
    })
    filtered.sort((taskA, taskB) => {
      let attribute = sort ? sort.attribute : 'pin_task';
      let direction = sort ? sort.dir : 'asc'
      switch (attribute) {
        case 'pin_task':
          return Boolean(taskB.pin_task) - Boolean(taskA.pin_task);
        case 'targetDate':
          if (direction === 'asc') {
            return moment(taskA.end_date).isBefore(taskB.end_date) ? -1 : moment(taskB.end_date).isBefore(taskA.end_date) ? 1 : 0
          } else {
            return moment(taskA.end_date).isBefore(taskB.end_date) ? 1 : moment(taskB.end_date).isBefore(taskA.end_date) ? -1 : 0
          }
        case 'priorityText':
          return compareTasks(taskA.priorityText, taskB.priorityText, direction)
        case 'assignee':
          let taskAAssignee = taskA.assignee ? `${taskA.assignee.firstname || ''} ${taskA.assignee.lastname || ''}` : '';
          let taskBAssignee = taskB.assignee ? `${taskB.assignee.firstname || ''} ${taskB.assignee.lastname || ''}` : '';
          return compareTasks(taskAAssignee, taskBAssignee, direction);
        case 'departmentText':
          return compareTasks(taskA.departmentText, taskB.departmentText, direction)
        case 'title':
          return compareTasks(taskA.title, taskB.title, direction)
        case 'status':
          return compareTasks(taskA.status, taskB.status, direction)
      }
    })
  }
  return filtered;
}
export const isListReady = (state) => {
  return Boolean(
    getAssigneeList(state) && getRepeationOccurList(state) &&
    getAllSource(state) && getProrityList(state) && getDepratmentList(state) &&
    getDashBoardContent(state) && getAllAssements(state) && getCustomTypes(state)
  )
}
export const isAssessmentLoaded = (state) => {
  return Boolean(state[STORE_NAME][LIST_ASSEMENT])
}
export const getPriorityById = (state, id, labelOnly = false) => {
  const priorities = getProrityList(state);
  const priority = priorities.find(_p => {
    return _p.id === id
  });
  return priority ? (labelOnly ? priority.label : priority) : ''
}
export const getAssigneeById = (state, id, dept, labelOnly = false) => {
  const assignees = getAssigneeList(state);
  let assignee = assignees.find(_p => {
    return (_p.id === id || _p.guid === id)
  });
  if (!assignee && dept) {
    let responsibles = getDepartmentAuditee(state, dept);
    assignee = responsibles.find(_p => {
      return (_p.id === id || _p.guid === id)
    });
  }
  return assignee ? (labelOnly ? `${assignee.firstname} ${assignee.lastname || ''}` : assignee) : ''
}
export const getDepartmentById = (state, id, labelOnly = false) => {
  const departments = getDepratmentList(state);
  const dept = departments.find(_p => {
    return _p.id === id
  });
  return dept ? (labelOnly ? dept.label : dept) : ''
}
export const getStatusById = (state, id, labelOnly = false) => {
  const statuss = getStatusList(state);
  const status = statuss.find(_p => {
    return _p.id === id
  });
  return status ? (labelOnly ? status.label : status) : ''
}
export const getAccountableById = (state, id, labelOnly = false) => {
  const accountables = getAccountableList(state);
  const accountable = accountables.find(_p => {
    return _p.id === id
  });
  return accountable ? (labelOnly ? accountable.label : accountable) : ''
}
export const getSourceById = (state, task, labelOnly = false) => {
  let sources = getAllSource(state), source;
  if (Array.isArray(sources)) {
    source = sources.find(_p => {
      return (
        (Number(task.incident_id) === Number(_p.id)) ||
        (Number(task.asmt_id) === Number(_p.id)) ||
        (Number(task.id) === Number(_p.id)) ||
        (String(task.activity_type).toLowerCase() === String(_p.id).toLowerCase())
      )
    });
  }
  return source ? (labelOnly ? source.label : source) : ''
}
export const getSourceWithTaskById = (state, id, labelOnly = false) => {
  let withTasks = getAllSourceWithTasks(state);
  let source = withTasks.find(_p => {
    if (_p.asmt && _p.asmt.guid) {
      return _p.asmt.guid === id
    }
    return (Number(_p.id) === Number(id)) || (String(_p.id).toLowerCase() === String(id).toLowerCase() || _p.guid === id)
  });
  return source ? (labelOnly ? source.label : source) : ''
}
export const getStatusList = (state) => {
  return state[STORE_NAME][LIST_STATUS];
}
export const isCommentAdded = (state) => {
  return state[STORE_NAME].commentAdding;
}
export const isObserveAdded = (state) => {
  return state[STORE_NAME].observAdded;
}
export const isEvidenceAttached = (state) => {
  return state[STORE_NAME].evidenceAttaching;
}
export const getTask = (state, taskId) => {
  const tasks = getAllTasks(state);
  let task = tasks.find((_task) => _task.id === taskId);
  if (task) {
    const assessments = state[STORE_NAME][LIST_ASSEMENT];
    let taskAssesment = assessments.find(_ => Number(_.id) === Number(task.asmt_id));
    if (taskAssesment) {
      task.assessmentPath = 'Assessments / ' + taskAssesment.label
    }
    if (!taskAssesment) {
      const incidents = getAllIncidents(state);
      taskAssesment = (incidents || []).find(_ => Number(_.id) === Number(task.incident_id));
      if (taskAssesment) {
        task.assessmentPath = 'Incident / ' + taskAssesment.title
      }
    }
    if (!taskAssesment) {
      const activityTypes = state[STORE_NAME][LIST_ACTIVITY];
      taskAssesment = (activityTypes || []).find(_ => _.id === task.activity_type);
      if (taskAssesment) {
        task.assessmentPath = 'Activity / ' + taskAssesment.label
      }
    }
    if (!taskAssesment) {
      const customTypes = state[STORE_NAME][LIST_CUSTOMTYPES];
      taskAssesment = (customTypes || []).find(_ => _.id === task.activity_type);
      if (taskAssesment) {
        task.assessmentPath = 'Custom / ' + taskAssesment.label
      }
    }
    if (!taskAssesment) {
      task.assessmentPath = 'Assessments / ' + task.asmt_id;
    }
    return { ...task, due_date: getTaskDueDate(task) };
  }
  return null
}
export const isTaskFetched = (state) => {
  return state[STORE_NAME].taskFetched
}
export const isTaskUpdated = (state) => {
  return state[STORE_NAME].updated;
}
export const getTotalPages = (state) => {
  const totalCount = state[STORE_NAME].tatalCount;
  let totalPages = 0;
  if (totalCount > 0) {
    let rem = totalCount % TASKS_PER_PAGE;
    totalPages = parseInt(totalCount / TASKS_PER_PAGE);
    totalPages += rem > 0 ? 1 : 0;
  }
  return totalPages;
}

export const fetchDashBoard = () => {
  return { type: FETCH_DASHBOARD }
}
export const getDashBoardContent = (state) => {
  return state[STORE_NAME].dashboard
}
export const getDepartmentVsPrority = (state) => {
  const dashboard = getDashBoardContent(state);
  return dashboard ? dashboard.deptVsPriority : null
}
export const getDepartmentStatus = (state) => {
  const dashboard = getDashBoardContent(state);
  return dashboard ? dashboard.deptStatus : null
}
export const getStatusVsPriority = (state) => {
  const dashboard = getDashBoardContent(state);
  return dashboard ? dashboard.statusVsPriorty : null
}
export const getAssmentHasTask = (state) => {
  let taskAss = getAssmentPriorityMap(state);
  let assessments = null, activityTypes, customTypes;
  activityTypes = getActivityTypes(state);
  customTypes = getCustomTypes(state);
  if (taskAss && Array.isArray(activityTypes) && Array.isArray(customTypes)) {
    assessments = [];
    taskAss.forEach((_ass) => {
      let ass = { ..._ass };
      let isActivityType = activityTypes.find((_) => _.id === ass.id);
      let isCustomType = customTypes.find((_) => _.id === ass.id);
      if (isActivityType || isCustomType) {
        return;
      }
      ass.group = 'Auditor Assessments';
      let details = getSourceById(state, ass);
      if (details) {
        ass.asmt = details;
        if (details.lib1_model && details.lib1_model.icon_url) {
          ass.logo = details.lib1_model.icon_url;
        }
        if (details.ent_report) {
          ass.reporturl = axios.getFullPath(`/R/${details.ent_report.guid}/report_detail`)
        }
      }
      assessments.push(ass)
      assessments.sort((a, b) => {
        const dateA = a.asmt ? moment(a.asmt.created_at) : moment(0);
        const dateB = b.asmt ? moment(b.asmt.created_at) : moment(0);
        if (dateB.isAfter(dateA)) {
          return 1;
        } else if (dateB.isBefore(dateA)) {
          return -1;
        } else {
          return 0;
        }
      });
    });
  }
  return Array.isArray(assessments) ? assessments : null
}
export const getActivityTypesTasks = (state) => {
  const dashboard = getDashBoardContent(state), actionsTypes = getActivityTypes(state);
  if (dashboard && Array.isArray(dashboard.actvVsPriority) && dashboard.actvVsPriority.length > 0 && actionsTypes) {
    let result = [];
    dashboard.actvVsPriority.forEach((_) => {
      let index = actionsTypes.findIndex((ac) => ac.id === _.id);
      if (index !== -1) {
        result.push({ ..._, group: 'Activity Types' })
      }
    });
    return result;
  }
  return null;
}
export const getCustomTypesTasks = (state) => {
  const dashboard = getDashBoardContent(state), customTypes = getCustomTypes(state);
  if (dashboard && Array.isArray(dashboard.actvVsPriority) && dashboard.actvVsPriority.length > 0 && customTypes) {
    let result = [];
    dashboard.actvVsPriority.forEach((_) => {
      let index = customTypes.findIndex((ac) => ac.id === _.id);
      if (index !== -1) {
        result.push({ ..._, group: 'Custom' })
      }
    });
    return result;
  }
  return null;
}
export const getIncidentsTasks = (state) => {
  let result = null;
  const dashboard = getDashBoardContent(state), incidents = getAllIncidents(state);
  if (dashboard && Array.isArray(dashboard.incVsPriority) && dashboard.incVsPriority.length > 0 && incidents) {
    result = [];
    dashboard.incVsPriority.forEach((_) => {
      let index = incidents.findIndex((ac) => Number(ac.id) === Number(_.id));
      if (index !== -1) {
        result.push({ ..._, group: 'Incidents' })
      }
    });
  }
  return result
}
export const getAssmentPriorityMap = (state) => {
  const dashboard = getDashBoardContent(state);
  return dashboard ? dashboard.asmtVsPriorty : null
}
export const isBulkUploadCompleted = (state) => {
  return state[STORE_NAME].bulkUploading
}
export const getBulkUploads = (state) => {
  return state[STORE_NAME].bulkUploads
}
export const getNotificationFreqs = (state) => {
  return state[STORE_NAME].notificationFreq
}
export const getTaskPerPage = () => {
  return TASKS_PER_PAGE
}
export const encyptDataObject = (iData) => {
  let parsed = JSON.stringify(iData);
  parsed = encode(parsed);
  return parsed;
}
export const decryptDataObject = (iDataString) => {
  let decoded = decode(iDataString);
  decoded = JSON.parse(decoded);
  return decoded;
}
export const getOpenTask = (state) => {
  return state[STORE_NAME].openTask
}