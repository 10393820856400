import React from 'react';
import { connect } from "react-redux";
import { getLoading, getAlert, clearAlert } from "../../store/common";

export class AppErrorBoundry extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='col full flex-ctr'>
          <h1>{this.props.message || 'Something went wrong. Please check logs'}</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

const ScreenComponent = (props) => {
  const { className } = props;
  return (
    <div className={'col full home ' + (className || '')}>
      {props.children}
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    alert: getAlert(state)
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    clearAlert: () => {
      dispatch(clearAlert())
    }
  }
}
export const Screen = connect(mapStateToProps, mapDispatchToProps)(ScreenComponent);

export const UserAvatar = (props) => {
  let { firstname, lastname, label } = props.user, avatarText;
  if (label) {
    avatarText = label.split(" ").filter((_) => String(_).trim().length > 0);
    avatarText = avatarText.length === 1 ? `${avatarText[0][0]}${avatarText[0][1]}` : `${avatarText[0][0]}${avatarText[1][0]}`
  } else {
    avatarText = (typeof lastname == "string" && lastname.length > 0) ? `${firstname[0]}${lastname[0]}` : firstname.substring(0, 2);
  }
  return (
    <div
      style={props.color ? { backgroundColor: props.color } : {}}
      className={`col flex-ctr avatar ${props.small ? 'h6 small' : 'h4'} ${props.className || ''} ${Boolean(props.dark) ? 'dark' : ''}`}>
      {avatarText}
    </div>
  )
}

export const AvatarCard = (props) => {
  const { user, dark, color, fontClassName } = props;
  return (
    <div className={`row ${props.className || ''}`}>
      <UserAvatar user={user} small={props.small} dark={dark} color={color} />
      <div className='col flex-full'>
        <span className={fontClassName || 'h6'}>{user.firstname || user.label} {user.lastname || ''}</span>
      </div>
    </div>
  )
}