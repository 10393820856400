import { ReducerRegistry } from "./ReducerRegistry";
import { SagaRegistry } from "./SagaRegistry";
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

export function createAppStore() {
  const sagaMiddleware = createSagaMiddleware()
  const reducers = ReducerRegistry.combineReducers();
  const rootSaga = SagaRegistry.getAllSagas();
  const store = createStore(reducers, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);
  return store;
}
export * from "./common";
export * from "./user";
export * from './tasks';