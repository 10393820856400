import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  HomeScreen,  StatsScreen, TaskScreen, TaskHomeScreen, SourceScreen, SettingsScreen, BulkUploadScreen, PageNotFoundScreen
} from "../screens";

export const TaskRouter = (props) => {
  return (
    <TaskHomeScreen {...props}>
      <Routes>
        <Route path="/" element={<TaskScreen />} />
        <Route path="/bulk-upload-tasks" element={<BulkUploadScreen />} />
      </Routes>
    </TaskHomeScreen>
  );
}

export const MainRouter = (props) => {
  return (
    <HomeScreen {...props}>
      <Routes>
        <Route path="/T/*" element={<TaskRouter {...props} />} />
        {/* <Route path="/S" element={<SourceScreen />} /> */}
        <Route path="/S" index element={<StatsScreen />} />
        <Route path="/" element={<Navigate replace to="/T" />} />
        <Route path="*" element={<PageNotFoundScreen />} />
      </Routes>
    </HomeScreen>
  );
}