import {
  STORE_NAME, LOGOUT,
  LOGIN, ROLE_RESPONSIBLE, ROLE_REVIEWER
} from "./actionsType";
import "./middleware";
import "./reducer";
import { VALIDATE_TOKEN } from "../common";
const Roles = {
  "Auditee": "Auditee",
  "Assessor": "Assessor",
  "Architect": "Architect",
  "Organiser": "Organiser",
  "Administrator": "Administrator"
}
export const login = (email, password) => {
  return { type: LOGIN, payload: { email, password } }
}
export const logout = () => {
  return { type: LOGOUT }
}
export const isLoggedIn = (state) => {
  return state[STORE_NAME].loginState
}
export const isLoggedOut = (state) => {
  return state[STORE_NAME].loggedOut
}
export const validateToken = (token) => {
  return { type: VALIDATE_TOKEN, payload: { token: token } }
}
export const getUser = (state) => {
  return state[STORE_NAME].user
}
export const isResponsible = (state) => {
  const user = getUser(state);
  return user.role && user.role.arc_role_name === ROLE_RESPONSIBLE;
}
export const isReviewer = (state) => {
  const user = getUser(state);
  return user.role && user.role.arc_role_name === ROLE_REVIEWER;
}
export const isAdmin = (user) => {
return user.role.name === Roles.Administrator
}
export const isOrganizer = (user) => {
  return user.role.name === Roles.Organiser
}
export const isAccessor = (user) => {
  return user.role.name === Roles.Assessor
}
export const isArchitect = (user) => {
  return user.role.name === Roles.Architect
}
export const isAuditee = (user) => {
  return user.role.name === Roles.Auditee
}
export const isSupervisor = (supervisor_id,user) => {
  return supervisor_id && user.id && supervisor_id === user.id
}