import './App.scss';
import { Provider } from 'react-redux';
import { createAppStore } from "./store";
import AuditorApp from "./routers";

const AppStore = createAppStore()
function App() {
  return (
    <Provider store={AppStore}>
      <AuditorApp />
    </Provider>
  );
}

export default App;
