export const STORE_NAME = "TASK";
export const TASKS_PER_PAGE = process.env.REACT_APP_TASK_PER_PAGE || 7;
export const LIST_DEPT = 'department_list';
export const LIST_PRIORITY = 'priority_list';
export const LIST_ASSEMENT = 'assessments';
export const LIST_INCIDENTS = 'incidents';
export const LIST_STATUS = 'status_list';
export const LIST_REPETATIONS = 'repetition_occurs_list';
export const LIST_ACTIVITY = 'activity_type_list';
export const LIST_CUSTOMTYPES = 'custom_type_list';
export const LIST_ASSIGNEE = 'auditee_list';
export const LIST_ACCOUNTABLE = 'accountable_list';
export const LIST_SUPERVISOR = 'supervisor_list';
export const LIST_MAPPED_ORGS = 'mapped_orgs';
export const FETCH_MAPPED_ORGS = 'FETCH_MAPPED_ORGS';
export const FETCH_DEFINED_LIST = 'FETCH_PREDEFINED_LIST';
export const FETCH_DEFINED_LIST_SUCCESS = 'FETCH_PREDEFINED_LIST_SUCCESS';
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const RESET_TASK_CREATE_STATUS = 'RESET_TASK_CREATE_STATUS';
export const FETCH_TASKS = 'FETCH_TASKS';
export const SEARCH_TASKS = 'SEARCH_TASKS';
export const FILTER_TASKS = 'FILTER_TASKS';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const ATTACH_TASK_EVIDENCE = 'ATTACH_TASK_EVIDENCE';
export const ATTACH_TASK_EVIDENCE_SUCCESS = 'ATTACH_TASK_EVIDENCE_SUCCESS';
export const ADD_TASK_COMMENT = 'ADD_TASK_COMMENT';
export const ADD_TASK_COMMENT_SUCCESS = 'ADD_TASK_COMMENT_SUCCESS';
export const SET_MAX_PIN_COUNT = 'SET_MAX_PIN_COUNT';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SORT_MODEL = 'SET_SORT_MODEL';
export const ADD_CUSTOM_TYPE = 'ADD_CUSTOM_TYPE';
export const IS_OBSERV_ADDED = 'IS_OBSERV_ADDED';
export const OPEN_TASK = 'OPEN_TASK';
export const OPEN_TASK_SUCCESS = 'OPEN_TASK_SUCCESS';
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';

export const BULK_UPLOAD_TASK = 'BULK_UPLOAD_TASK';
export const BULK_UPLOAD_TASK_SUCCESS = 'BULK_UPLOAD_TASK_SUCCESS'

export const FETCH_BULK_UPLOAD = 'FETCH_BULK_UPLOAD';
export const FETCH_BULK_UPLOAD_SUCCESS = 'FETCH_BULK_UPLOAD_SUCCESS'

export const DEPT_RESPONSIBLE = 'DEPARTMENT_RESPONSIBLE';
export const DEPT_RESPONSIBLE_SUCCESS = 'DEPT_RESPONSIBLE_SUCCESS';

export const NOTIF_FREQ_LIST = 'NOTIF_FREQ_LIST';
export const NOTIF_FREQ_LIST_SUCCESS = 'NOTIF_FREQ_LIST_SUCCESS';

export const UPDATE_NOTIF_FREQ = 'UPDATE_NOTIF_FREQ';
export const UPDATE_NOTIF_FREQ_SUCCESS = 'UPDATE_NOTIF_FREQ_SUCCESS';

export const GET_API_KEY = 'GET_API_KEY';

